<template>
  <main>
    <div class="modal fade " id="perfilUsuario" tabindex="-1" role="dialog" aria-labelledby="BuscarSede" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
              <h4 class="modal-title "><i class="fas fa-id-card fa-fw"></i> DATOS DEL USUARIO</h4>
              <button type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close">
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div class="modal-body">
            <div class="card border-left-lg border-left-primary shadow-none">
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-2 col-md-6 col-sm-12">
                    <div class="text-center" v-if="usuario.sexo === 'F'">
                        <img class="mg-account-profile rounded-circle mt-2 bg-gray-200" src="@/assets/img-genero/mujer.png" width="96">
                    </div>
                    <div class="text-center" v-else-if="usuario.sexo === 'M'">
                        <img class="mg-account-profile rounded-circle mt-2 bg-gray-200" src="@/assets/img-genero/hombre.png" width="100">
                    </div>
                  </div>
                  <div class="col-lg-10 col-md-12 col-sm-12">
                    <h4 class="text-bold">{{usuario.nombre_1}} {{usuario.nombre_2}} {{usuario.apellido_1}} {{usuario.apellido_2}}</h4>
                    <h6 ><span v-if="usuario.tipo_documento" class="text-dark">({{usuario.tipo_documento.codigo }}) {{usuario.documento}} | <span class="text-primary">F. Nac. {{usuario.fecha_nacimiento | formatDate}}</span></span></h6>
                    <h3 ><span class="badge badge-primary-soft text-black">{{usuario.years}}</span> </h3> 
                  </div>                
                </div>
              </div>
            </div>

            <div class="card border-left-lg border-left-primary shadow-none mt-2">
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-2 col-md-6 col-sm-12">
                    <div class="text-center">
                        <i class="mg-account-profile rounded-circle mt-2 bg-gray-200 fas fa-id-card-alt fa-4x"  width="96"></i>
                    </div>
                  </div>           
                  <div class="col-lg-10 col-md-12 col-sm-12">
                    <h6 class="">EPS: <span class="text-muted">{{eps}}</span></h6>
                    <h6>
                      Sexo:
                      <span class="text-body" >
                        {{usuario.sexo}}
                      </span>
                    </h6>
                    <h6>
                      Estado Civil:
                      <span class="text-body" v-if="usuario.estado_civil">
                        {{usuario.estado_civil.descripcion}}
                      </span>
                    </h6>
                  </div>
                </div>
              </div>
            </div>

            <div class="card border-left-lg border-left-primary shadow-none mt-2">
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-2 col-md-6 col-sm-12">
                    <div class="text-center">
                        <i class="mg-account-profile rounded-circle mt-2 bg-gray-200 fas fa-address-book fa-4x"  width="96"></i>
                    </div>
                  </div>           
                  <div class="col-lg-10 col-md-12 col-sm-12">
                    <h6  class="">
                      DIRECCIÓN: <span class="text-body">{{usuario.direccion}}</span>
                    </h6> 
                    <h6  class="">DEPARTAMENTO:
                      <span class="text-body" v-if="usuario.municipio">
                        {{usuario.municipio.departamento.nombre}}
                      </span>
                    </h6> 
                    <h6  class="">MUNICIPIO:
                      <span class="text-body" v-if="usuario.municipio">
                        {{usuario.municipio.nombre}}
                      </span>
                    </h6>
                    <h6  class="">TELEFONO:
                      <span class="text-body">{{usuario.telefono}}</span>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-light btn-sm" data-dismiss="modal">Cerrar<i class="fa fa-times-circle fa-fw"></i></button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import {isEmpty} from 'lodash';
export default {
     props:['usuario'],
     data(){
      return {
        eps:{}
      }
     },
     watch:{
        usuario:function(){
          if(isEmpty(this.usuario.contratos)){
                this.eps='Sin datos...';
            }else{
                this.eps = this.usuario.contratos[0].contrato.eps.DESEPS
            }
        }
    }
}
</script>
