import Service from './Service';
import { isEmpty } from 'lodash';
const resource = '/api/usuarios-evt';

export default {
    getUserByDocument(documento) {
        let url = `${resource}/get?by=document&documento=${documento}`;
        return Service.get(url);
    },
    index(search = '', pagination = {}) {

        let url = `/api/usuarios-evt?`;

        url += search !== '' ? `s=${search}` : '';
        url += (!isEmpty(pagination)) ? `&paginate=true&per_page=${pagination.per_page}&page=${pagination.page}` : ''

        return Service.get(url);
    },
    show(id) {
        let url = `/api/usuarios-evt/ver/${id}`;
        return Service.get(url);
    },
    updateDataBasic(obj){
        return Service.put(`${resource}/updateDataBasic`, obj);
    }


}
