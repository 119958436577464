import Service from "./Service";
const baseurl = '/api/historia';
import makeParams from "../utils/functions/makeParams";
import { isEmpty } from "lodash";
export default {
    store(obj) {
        return Service.post(`${baseurl}/store`, obj);
    },
    show(id) {
        return Service.get(`${baseurl}/show/${id}`);
    },
    showByLast(id) {
        return Service.get(`${baseurl}/show-by-last/${id}`);
    },
    showByIdUser(id) {
        return Service.get(`${baseurl}/show-by-idUser/${id}`);
    },
    updateCheckpoint(id, checkpoint) {
        return Service.put(`${baseurl}/update/checkpoint`, { id: id, checkpoint: checkpoint });
    },
    showUnfinished(id_usuario) {
        return Service.get(`${baseurl}/show-unfinished/${id_usuario}`);
    },
    print(id) {
        return `${process.env.VUE_APP_BASE_PATH}${baseurl}/print/${id}`
    },
    resumen(id) {
        return `${process.env.VUE_APP_BASE_PATH}${baseurl}/resumen/${id}`
    },
    formula(id) {
        return `${process.env.VUE_APP_BASE_PATH}${baseurl}/formula/${id}`
    },
    orden(id) {
        return `${process.env.VUE_APP_BASE_PATH}${baseurl}/orden/${id}`
    },
    close(data) {
        return Service.post(`${baseurl}/close`, data);
    },
    cerrarHistoria(data) {
        return Service.post(`${baseurl}/cerrar`, data);
    },
    async showByIdPaciente(search = '',pagination = {},id) {
        let url = `${baseurl}/show-by-idPaciente/${id}?`;

        url += search !== '' ? `s=${search}` : '';

        url += (!isEmpty(pagination))
            ? `&paginate=true&per_page=${pagination.per_page}&page=${pagination.page}`
            : '';

        return Service.get(url);
    },
    async showByHC(search = '', pagination = {}) {
       
        const hasAditionalParams = !!pagination['params'];

        let aditionalParams = {};

        if(hasAditionalParams){
            for(const param in pagination['params']){
                if(
                    pagination['params'][param] !== ''
                    && pagination['params'][param] !== undefined
                    && pagination['params'][param] !== null
                ){
                    aditionalParams[param] = pagination['params'][param]
                }
            }
        }

        const params = {
            ...(search !== '' ? {s:search} : {}),
            ...(isEmpty(pagination['per_page']) ? {per_page : pagination['per_page']} : {}),
            ...(isEmpty(pagination['page']) ? {page : pagination['page']} : {}),
            ...(hasAditionalParams ? {...aditionalParams} : {}),
        };
        console.log(params);
        return Service.get(`${baseurl}/showByHC`, {
            params : {
                ...params
            }
        });
    },
    cambiarEstado(id) {
        return Service.put(`${baseurl}/cambiarEstado/${id}`);
    },
    reportByHCExcel
    (params = {}) {
        const paramsArr = [];
        for (const param in makeParams(params)) paramsArr.push(`${param}=${params[param]}`);
        window.open(`${baseurl}/reporte-historias-excel?${paramsArr.join('&')}`);
    },
}
